<template>
  <div>
    <div v-show="showInformations" class="wrapper">
      <div class="wrapper__card">
        <div class="wrapper__card__header">
          <div class="left">
            <span>Log Importação</span>
          </div>
        </div>
        <v-form ref="filterForm">
          <div class="wrapper__card__fitler my-6">
            <div>
              <v-text-field
                v-model="filter.dateFrom"
                label="Data de"
                outlined
                dense
                rounded
                type="date"
                hide-details
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                v-model="filter.dateTo"
                label="Data até"
                outlined
                dense
                rounded
                type="date"
                hide-details
              ></v-text-field>
            </div>
            <div>
              <z-select
                v-model="filter.status"
                :items="statusItems"
                label="Status"
                itemText="text"
                itemValue="value"
                dense
                outlined
                rounded
                hide-details
              />
            </div>
            <div>
              <z-select
                v-model="filter.type"
                :items="typesItems"
                label="Tipo"
                itemText="text"
                itemValue="value"
                dense
                outlined
                rounded
                hide-details
              />
            </div>
            <div>
              <z-btn text="filtrar" primary @click="filterInfos"></z-btn>
            </div>
            <div>
              <z-btn text="resetar" @click="resetFilter"></z-btn>
            </div>
            <div>
              <z-btn
                text="Baixar"
                :is-loading="loadingDownload"
                @click="downloadCsv"
              ></z-btn>
            </div>
          </div>
        </v-form>
        <v-row>
          <v-col lg="3" md="3" sm="12" xs="12" cols="12" class="mt-2">
            <CardReport
              icon="mdi-check-all"
              border-color-prop="#008000"
              main-color-prop="rgb(0, 128, 0)"
            >
              <template #title> Sucesso </template>
              <template #value>
                {{ formateNumberToLocal(getLogTotals("success")) }}
              </template>
            </CardReport>
          </v-col>
          <v-col lg="3" md="3" sm="12" xs="12" cols="12" class="mt-2">
            <CardReport
              icon="mdi-alert"
              border-color-prop="#FF0000"
              main-color-prop="rgb(255, 0, 0)"
            >
              <template #title> Erro </template>
              <template #value>
                {{ formateNumberToLocal(getLogTotals("error")) }}
              </template>
            </CardReport>
          </v-col>
        </v-row>
        <div class="wrapper__card__body">
          <z-pagination
            :autoPagination="false"
            :items="tableResponse"
            :numberOfPages="filter.pages"
            :currentPage="filter.page"
            icon-previous="mdi-chevron-left"
            icon-next="mdi-chevron-right"
            @change:page="updatePageInfo"
            @click:next="goNext"
            @click:previous="backPrevious"
          >
            <template #data="{ paginatedItems }">
              <div class="table-wrapper">
                <z-table
                  class="table"
                  :headers="headers"
                  :items="paginatedItems"
                  :isLoading="loadingTableInfos"
                  icon-next="mdi-chevron-right"
                  icon-previous="mdi-chevron-left"
                >
                  <template #[`item.created`]="{ item }">
                    <span>{{ formateDate(item.created) }}</span>
                  </template>
                </z-table>
              </div>
            </template>
          </z-pagination>

          <!-- <div class="wrapper__card__body__left">
            <div class="card-parameters">
              <div class="card-parameters__table">
                <v-data-table
                  :headers="headers"
                  :items="tableResponse"
                  hide-default-footer
                  :loading="loadingTableInfos"
                  no-data-text="Nenhum dado encontrado"
                  loading-text="Buscando dados..."
                >
                  <template #[`item.created`]="{ item }">
                    <span>{{ formateDate(item.created) }}</span>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div v-show="!showInformations" class="wrapper no-data">
      <div class="wrapper__card">
        <z-loading v-if="!errorOnFetchData" />
        <div v-else>
          <span>Não foi possivel buscar as informações solicitadas</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ApiClient from "@/services/ApiClient";
import CardReport from "../../Shared/ZCardReportInfo.vue";

export default {
  components: { CardReport },
  data() {
    return {
      page: 1,
      loadingTableInfos: false,
      statusItems: [
        { text: "Sucesso", value: "success" },
        { text: "Erro", value: "error" },
      ],
      typesItems: [
        { text: "Api", value: "api" },
        { text: "Automático", value: "auto" },
      ],
      filter: {
        dateFrom: moment().add(-31, "days").format("yyyy-MM-DD"),
        dateTo: moment().endOf("day").format("yyyy-MM-DD"),
        status: "",
        type: "",
        page: 1,
        pageSize: 4,
        pages: 1,
      },
      errorOnFetchData: false,
      logCounts: [],
      connectionId: "",
      connectionFromPage: "",
      objectMounted: null,
      showInformations: false,
      mock: {
        schema: "public",
        id: "8d79710e-334e-4485-81f1-b17d708ab736",
      },
      token: "",
      tableResponse: [],
      http: new ApiClient(),
      // headers: [
      //   { text: "DATA", value: "date", sortable: false },
      //   { text: "STATUS", value: "status", sortable: false },
      //   { text: "RETORNO", value: "return", sortable: false },
      //   { text: "PAYLOAD", value: "payload", sortable: false },
      // ],
      headers: [
        { text: "DATA", value: "created", sortable: false },
        { text: "STATUS", value: "status", sortable: false },
        { text: "RETORNO", value: "operationResult", sortable: false },
        { text: "PAYLOAD", value: "queuePayload", sortable: false },
      ],

      loadingDownload: false,
    };
  },
  created() {
    this.connectionFromPage = this.$route.params.page;
    this.connectionId = this.$route.params.id;

    this.getLogCount();

    if (this.connectionFromPage.toLowerCase() == "primarydata")
      this.getPrimaryDataInformation();
    if (this.connectionFromPage.toLowerCase() == "additionaldata")
      this.getAdditionalDataInformation();
    if (this.connectionFromPage.toLocaleLowerCase() == "conversiondata")
      this.getConversionDataInformation();
  },
  // computed: {
  //   tableItems() {
  //     const FINAL_ARR = [];
  //     if (this.tableResponse != null || this.tableResponse != undefined) {
  //       //   const LIST = JSON.parse(this.tableResponseMock.fields);
  //       const LIST = JSON.parse(this.tableResponse.fields);
  //       const ARR = Object.keys(LIST);

  //       for (const item of ARR) {
  //         FINAL_ARR.push({
  //           name: item,
  //           type: "string",
  //         });
  //       }
  //     }
  //     return FINAL_ARR;
  //   },
  // },
  methods: {
    formateNumberToLocal(num) {
      return num ? parseFloat(num).toLocaleString("pt-BR") : 0;
    },
    getLogTotals(type) {
      let item = this.logCounts.find((x) => x._id === type);

      if (item) return item.count_total;
      return 0;
    },
    async downloadCsv() {
      let route = "";
      if (this.connectionFromPage.toLowerCase() == "primarydata")
        route = "dbConfiguration";
      if (this.connectionFromPage.toLowerCase() == "additionaldata")
        route = "additionalDataConfiguration";
      if (this.connectionFromPage.toLocaleLowerCase() == "conversiondata")
        route = "conversionDataConfiguration";

      try {
        const response = await this.http.get(
          `${route}/log/export/${this.connectionId}?status=${this.filter.status}&endDate=${this.filter.dateTo}&startDate=${this.filter.dateFrom}&importType=${this.filter.type}`
        );

        const anchor = document.createElement("a");
        anchor.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(response);
        anchor.target = "_blank";
        anchor.download = "log.csv";
        anchor.click();
        this.loadingDownload = false;
      } catch (error) {
        this.loadingDownload = false;
      }
    },
    filterInfos() {
      this.getLogCount(this.connectionFromPage.toLowerCase() == "primarydata" ? 'dbConfiguration' : 'additionalDataConfiguration');

      if (this.connectionFromPage.toLowerCase() == "primarydata")
        this.getPrimaryDataInformation();
      if (this.connectionFromPage.toLowerCase() == "additionaldata")
        this.getAdditionalDataInformation();
      if (this.connectionFromPage.toLocaleLowerCase() == "conversiondata")
        this.getConversionDataInformation();
    },
    resetFilter() {
      this.filter.status = "";
      this.filter.dateFrom = "";
      this.filter.dateTo = "";
      this.filter.type = "";

      (this.filter.page = 1),
        (this.filter.pageSize = 4),
        (this.filter.pages = 1);

      this.filterInfos();
    },
    goToPage(name) {
      this.$router.push({ name });
    },
    formateDate(date) {
      moment.locale("pt-br");
      // console.log(moment(date).format('HH:mm:ss'))
      return moment(date).format("DD/MM/YYYY LT");
    },

    updatePageInfo(newPage) {
      this.filter.page = newPage;
      this.filterInfos();
    },
    goNext() {
      this.filter.page++;
      this.filterInfos();
    },
    backPrevious() {
      this.filter.page--;
      this.filterInfos();
    },
    async getLogCount() {
      const req = await this.http.get(
        `dbConfiguration/logCounts/${this.connectionId}?status=${this.filter.status}&endDate=${this.filter.dateTo}&startDate=${this.filter.dateFrom}&importType=${this.filter.type}&pageSize=${this.filter.pageSize}&page=${this.filter.page}`
      );

      this.logCounts = req;
    },
    async getPrimaryDataInformation() {
      this.loadingTableInfos = true;
      try {
        const req = await this.http.get(
          `dbConfiguration/log/${this.connectionId}?status=${this.filter.status}&endDate=${this.filter.dateTo}&startDate=${this.filter.dateFrom}&importType=${this.filter.type}&pageSize=${this.filter.pageSize}&page=${this.filter.page}`
        );
        this.tableResponse = req.items;
        this.filter.page = req.page;
        this.filter.pages = req.pages;
        setTimeout(() => {
          this.showInformations = true;
        }, 1500);
        this.errorOnFetchData = false;
        this.loadingTableInfos = false;
      } catch (error) {
        this.$toast.error("Erro ao buscar dados!");
        this.errorOnFetchData = true;
        this.loadingTableInfos = false;
      }
    },
    async getAdditionalDataInformation() {
      this.loadingTableInfos = true;
      try {
        const req = await this.http.get(
          `additionalDataConfiguration/log/${this.connectionId}?status=${this.filter.status}&endDate=${this.filter.dateTo}&startDate=${this.filter.dateFrom}&importType=${this.filter.type}&pageSize=${this.filter.pageSize}&page=${this.filter.page}`
        );
        this.tableResponse = req.items;
        setTimeout(() => {
          this.showInformations = true;
        }, 1500);
        this.errorOnFetchData = false;
        this.loadingTableInfos = false;
      } catch (error) {
        this.$toast.error("Erro ao buscar dados!");
        this.errorOnFetchData = true;
        this.loadingTableInfos = false;
      }
    },
    async getConversionDataInformation() {
      this.loadingTableInfos = true;
      try {
        const req = await this.http.get(
          `conversionDataConfiguration/log/${this.connectionId}?status=${this.filter.status}&endDate=${this.filter.dateTo}&startDate=${this.filter.dateFrom}&importType=${this.filter.type}&pageSize=${this.filter.pageSize}&page=${this.filter.page}`
        );
        this.tableResponse = req.items;
        setTimeout(() => {
          this.showInformations = true;
        }, 1500);
        this.errorOnFetchData = false;
        this.loadingTableInfos = false;
      } catch (error) {
        this.$toast.error("Erro ao buscar dados!");
        this.errorOnFetchData = true;
        this.loadingTableInfos = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);

  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    @include trackScrollBar;

    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
      .right {
        // border: 1px solid red;
        flex: 1 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;
        & > div {
          // &:first-child {
          //   width: 40%;
          // }
          width: 20%;
          // border: 1px solid;
        }

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }

      & > div {
        // border: 1px solid red;
      }
    }

    &__fitler {
      display: flex;
      flex-wrap: wrap;
      gap: $z-s-1 0.5rem;
      & > div {
        // flex: 0 0 auto;

        &:nth-child(3),
        &:nth-child(4) {
          max-width: 15%;

          @media (max-width: 808px) {
            max-width: 100%;
          }
        }
      }

      @media (max-width: 808px) {
        justify-content: flex-start;
        & > div {
          flex: 1 1 350px;
        }
      }
    }

    &__body {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: $z-s-1;
      overflow-x: auto;
      @include trackScrollBar;
      overflow-y: hidden;

      & > div {
        width: 100%;
        flex: 1 1 400px;
      }

      &__left {
        .card-key {
          background: #ececec;
          border-radius: 15px;
          padding: $z-s-1 1.5rem;
          &__line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              cursor: pointer;
            }
          }
        }
        .card-resource {
          .link-resource {
            font-size: 0.95rem;

            @media (max-width: 750px) {
              font-size: 0.75rem;
            }
          }
        }

        .card-parameters {
          &__table {
            height: 60vh;
            overflow-y: auto;
            @include trackScrollBar;
          }
        }
        & > div {
          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
        }
      }
      &__right {
        .tabs {
          .curl {
            margin-top: 0.5rem;
            background: #272822;
            padding: 0.8rem 0.5rem;
            color: white;
            font-size: 0.7rem;
            border-radius: 10px;
          }
        }
      }

      .table-wrapper {
        height: 400px !important;
        max-height: 400px;
        overflow-y: scroll;
        // overflow-x: auto;
        @include trackScrollBar;

        .no-data-table {
          text-align: center;
        }

        ::v-deep .v-data-table {
          table {
            th {
              &:first-child {
                min-width: 190px;
                max-width: 190px;
              }
            }
            td {
            }
          }
        }

        @media (min-width: 1550px) {
          height: auto;
          max-height: 750px;
        }
      }

      @media (min-width: 720px) and (max-width: 1079px) {
        .table-wrapper {
          height: 400px;
          max-height: 400px;
        }
      }

      @media (max-width: 890px) {
        height: auto;
        .table-wrapper {
          height: 350px;
          max-height: 350px;
        }
      }

      // // NOVAS TABELAS ADICIONADAS
      @media (min-width: 750px) {
        ::v-deep .v-data-table {
          // table {
          //   th {
          //     &:not(:first-child) {
          //       text-align: center !important;
          //     }
          //     &:last-child {
          //       text-align: right !important;
          //     }
          //   }
          //   td {
          //     &:not(:first-child) {
          //       text-align: center !important;
          //       & > div,
          //       span {
          //         margin: 0 auto !important;
          //       }
          //     }
          //     &:last-child {
          //       text-align: right !important;
          //     }
          //   }
          // }
        }
      }
    }

    .title-item {
      font-size: 1.25rem;
      font-weight: 600;
      display: block;
      margin-bottom: 0.5rem;
    }
  }
}
.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
